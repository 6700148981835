Ember.TEMPLATES["javascripts/discourse/connectors/user-summary-stat/dislike-count"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"value\",\"label\",\"icon\"],[[33,1,[\"dislike_count\"]],\"dislike.dislike_count\",\"thumbs-down\"]]]],[1,\" \\n\"]],[],false,[\"user-stat\",\"model\"]]","moduleName":"javascripts/discourse/connectors/user-summary-stat/dislike-count","isStrictMode":false});
Ember.TEMPLATES["javascripts/discourse/connectors/user-summary-stat/disliked-count"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"value\",\"label\",\"icon\"],[[33,1,[\"disliked_count\"]],\"dislike.disliked_count\",\"thumbs-down\"]]]],[1,\" \\n\"]],[],false,[\"user-stat\",\"model\"]]","moduleName":"javascripts/discourse/connectors/user-summary-stat/disliked-count","isStrictMode":false});
define("discourse/plugins/discourse-dislike/discourse/initializers/dislike-init", ["exports", "I18n", "discourse/lib/plugin-api", "@ember/runloop", "@ember/object", "rsvp"], function (_exports, _I18n, _pluginApi, _runloop, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function initializePlugin(api) {
    api.addPostMenuButton('dislike', attrs => {
      const count = attrs.dislikeCount;
      var className = attrs.disliked ? "toggle-dislike has-dislike fade-out" : "toggle-dislike dislike";
      var icon = attrs.disliked ? "thumbs-down" : "far-thumbs-down";
      var title = attrs.disliked ? "post.controls.undo_dislike" : "post.controls.dislike";
      var action = !attrs.disliked || attrs.canToggleDislike;

      if (count > 0 && action) {
        return {
          title: title,
          contents: count,
          icon: icon,
          iconRight: true,
          className: className,
          action: "dislike",
          position: "last"
        };
      } else if (count > 0) {
        return {
          title: "post.dislike_count_title",
          contents: count,
          icon: icon,
          iconRight: true,
          className: className,
          disabled: true,
          position: "last"
        };
      } else if (action) {
        return {
          title: title,
          icon: icon,
          className: className,
          action: "dislike",
          position: "last"
        };
      }
    });
    api.attachWidgetAction('post-menu', 'dislike', function () {
      var attrs = this.attrs,
          currentUser = this.currentUser,
          keyValueStore = this.keyValueStore;

      if (!currentUser) {
        keyValueStore && keyValueStore.set({
          key: "dislikedPostId",
          value: attrs.id
        });
        return this.sendWidgetAction("showLogin");
      }

      if (this.capabilities.canVibrate) {
        navigator.vibrate(5);
      }

      if (attrs.disliked) {
        return this.sendWidgetAction("toggleDislike");
      }

      this.sendWidgetAction("toggleDislike"); //    var $heart = $("[data-post-id=".concat(attrs.id, "] .toggle-dislike .d-icon"));
      //    $heart.closest("button").addClass("has-dislike");
      //    var scale = [1.0, 1.5];
      //    return new Promise(function (resolve) {
      //      this.animateHeart($heart, scale[0], scale[1], function () {
      //        this.animateHeart($heart, scale[1], scale[0], function () {
      //          this.sendWidgetAction("toggleDisike").then(function () {
      //            return resolve();
      //          });
      //        });
      //      });
      //    });
    });
    api.attachWidgetAction('post', 'toggleDislike', function () {
      var post = this.model;
      var dislikeAction = post.get("actionByName")["dislike"];
      var widget = this;

      if (dislikeAction && dislikeAction.get("canToggle")) {
        return dislikeAction.togglePromise(post).then(function (result) {
          widget.appEvents.trigger("page:dislike-toggled", post, dislikeAction);
          return widget._warnIfClose(result);
        });
      }
    });
    api.includePostAttributes('actionByName');
    api.addPostTransformCallback(transformedPost => {
      try {
        const dislikeAction = transformedPost.actionByName["dislike"];

        if (dislikeAction) {
          transformedPost.disliked = dislikeAction.acted;
          transformedPost.dislikeCount = dislikeAction.count;
          transformedPost.canToggleDislike = dislikeAction.get("canToggle");
        }
      } catch (e) {
        console.log(e.message);
        console.log(e.name);
      }
    });
  }

  var _default = {
    name: 'discourse-dislike',
    initialize: function () {
      (0, _pluginApi.withPluginApi)('0.11.1', api => initializePlugin(api));
    }
  };
  _exports.default = _default;
});

